import React from "react";
import "./MaintenancePage.module.css";

const MaintenancePage = () => (
  <main className="main">
    <div className="container">
      <img id="dylan" src={require("../../dylan.jpg")} alt="dylan" />
      <h1>Coming soon...</h1>
      <p>
        For now, please contact <strong>bookings@coverdinpunk.com</strong>
      </p>
      <p>
        or visit our{" "}
        <a href="https://www.facebook.com/Coverdinpunk">Facebook Page</a>
      </p>
    </div>
  </main>
);

MaintenancePage.propTypes = {};

MaintenancePage.defaultProps = {};

export default MaintenancePage;
