import "./App.css";
import MaintenancePage from "../MaintenancePage/MaintanencePage";

function App() {
  return (
    <div className="App">
      <body>
        <MaintenancePage />
      </body>
    </div>
  );
}

export default App;
